import { Component } from '@angular/core';
import { CfnEcmFrontInitialisationService } from '@smex-app-cfn/smex-module-cfn-ecm-frontend';

@Component({
    selector   : 'smex-app-cfn-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent {
    public initialized: Promise<boolean>;
    title = 'smex-app-cfn-frontend';

    constructor(private initializationService: CfnEcmFrontInitialisationService) {
        this.initialized = this.initializationService.initialised;
    }

}
