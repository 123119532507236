
import { Injectable } from "@angular/core" ;
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { WaitingFrontendComponent } from "../components/waitingfrontend.component" ;

/**
 * Ce service gère l’affichage de l’animation quand il faut attendre.
 */

@Injectable()
export class WaitingFrontendService
{
    /**
     * Nombre de processus en attente.
     */
    private m_WaitingCount : number = 0 ;

    /**
     * Quand le spinner est affichée, sa référence.
     */
    private m_OverlayRef: OverlayRef | null = null ;

    /**
     * Constructeur
     */
    constructor ( private m_Overlay: Overlay )
    {
    }

    /**
     * Afficher le spinner pendant qu’on attend.
     */
    private showWaiting ()
    {
        if ( ! this.m_OverlayRef )
            this.m_OverlayRef = this.m_Overlay.create() ;

        // Créer un ComponentPortal qui peut être attaché à un PortalHost
        let SpinnerOverlayPortal = new ComponentPortal ( WaitingFrontendComponent ) ;
        // Attacher le ComponentPortal au PortalHost
        this.m_OverlayRef.attach ( SpinnerOverlayPortal ) ;
    }

    private hideWaiting ()
    {
        if ( !! this.m_OverlayRef )
            this.m_OverlayRef.detach() ;
    }

    /**
     * Appelé en début d’un processus long pour indiquer qu’il faut attendre.
     */
    public startWaiting () : void
    {
        if ( this.m_WaitingCount === 0 )
            this.showWaiting () ;
        this.m_WaitingCount ++ ;
    }

    /**
     * Appelé en fin d’un processus long pour indiquer que l’attente est terminé.
     */
    public stopWaiting () : void
    {
        if ( this.m_WaitingCount > 0 )
        {
            this.m_WaitingCount -- ;
            if ( this.m_WaitingCount == 0 )
                this.hideWaiting () ;
        }
    }
}

