import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './shared/material.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
    imports: [CommonModule, MaterialModule, SharedModule],
    exports: [CommonModule, MaterialModule],
    providers: []
})
export class SmexLibCommonFrontendModule {}
