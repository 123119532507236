import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppModuleRouting } from './app-routing.module';
import { CfnSharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
    APP_PATH,
    ErrorHandlingFrontendModule,
    MaterialModule,
    MODULE_ID,
    TranslationFrontendService
} from '@numen/smex-lib-common-frontend';
import { APP_BASE_HREF } from '@angular/common';
import { InProgressInterceptor } from '@smex-app-cfn/smex-module-cfn-ecm-frontend';

@NgModule({
    declarations: [AppComponent],
    imports     : [
        BrowserAnimationsModule,
        AppModuleRouting,
        HttpClientModule,
        MaterialModule,
        ErrorHandlingFrontendModule,
        CfnSharedModule
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: (window as any)['base-href'] },
        { provide: APP_PATH, useValue: "cfn" },
        { provide: MODULE_ID, useValue: "cfn-ecm" },
        { provide: HTTP_INTERCEPTORS, useClass: InProgressInterceptor, multi: true},
        TranslationFrontendService
    ],
    bootstrap   : [AppComponent]
})
export class AppModule {
}
