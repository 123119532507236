import { NgModule } from '@angular/core';
import { LibCfnEcmCoreModule, LibCfnEcmSharedModule } from '@smex-app-cfn/smex-module-cfn-ecm-frontend';
import { SpaceContainerComponent } from './components/space-container/space-container.component';

@NgModule({
    declarations: [SpaceContainerComponent],
    imports     : [LibCfnEcmCoreModule, LibCfnEcmSharedModule],
    exports     : [SpaceContainerComponent, LibCfnEcmCoreModule, LibCfnEcmSharedModule],
    providers   : []
})
export class CfnSharedModule {
}
