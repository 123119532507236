import { NgModule } from '@angular/core';
import { SharedModule } from '@numen/smex-lib-common-frontend';
import { AccessControlDirective, MatAutocompleteScrollDirective } from './directives';
import { InProgressInterceptor } from './interceptors';


@NgModule({
    declarations: [
        AccessControlDirective,
        MatAutocompleteScrollDirective
    ],
    imports     : [
        SharedModule
    ],
    exports     : [
        AccessControlDirective,
        MatAutocompleteScrollDirective
    ],
    providers   : [
        InProgressInterceptor
    ]
})
export class LibCfnEcmCoreModule {
}
