
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

import { ErrorHandlingFrontendAlertDialogComponent } from "../components/errorhandlingfrontend-alert-dialog/errorhandlingfrontend-alert-dialog.component" ;
import { firstValueFrom } from 'rxjs';
import { SmexFrontendUtils } from '../../shared/services';

@Injectable()
export class ErrorHandlingFrontendService
{
    constructor(
        private m_Dialog : MatDialog
    )
    {
    }

    /**
     * Trouver l’exception de base d’une erreur.
     */
    public getBaseException ( Error : any ) : any
    {
        let Err : any = Error ;

        if (SmexFrontendUtils.isObject(Err))
        {
            if ( "rejection" in Error )
                Err = Error["rejection"] ;

            // Essayer de trouver l’objet "error" dans la valeur.
            if ( "error" in Err )
            {
                Err = Err["error"] ;
                if (Err instanceof ArrayBuffer) {
                    Err = new TextDecoder().decode(Err);
                    try {
                        Err = JSON.parse(Err);
                    } catch (_) {
                        // do nothing
                    }
                }
                if (SmexFrontendUtils.isObject(Err))
                {
                    // À l’intérieur le message est probablement JSON (au moins de SmartGED)
                    if ( SmexFrontendUtils.isString(Err["message"]) )
                    {
                        try
                        {
                            let MsgObj = JSON.parse ( Err["message"] ) ;
                            if (SmexFrontendUtils.isObject(MsgObj))
                            {
                                if ('message' in MsgObj) {
                                    Err = MsgObj;
                                }
                            }
                        }
                        catch (_) {
                            Err = Err["message"];
                        }
                    }
                    else
                    {
                        // Sinon les exceptions de notre serveur SmartUsers mettent le message ici.
                        if ( Array.isArray(Err["errors"]) )
                        {
                            for ( let Err2 of Err["errors"] )
                            {
                                let Msg = Err2["message"] ;
                                if (SmexFrontendUtils.isString(Msg)) {
                                    Err = Err2;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }

        return ( Err ) ;
    }

    /**
     * Déterminer si une erreur est une absence d’authentification.
     *
     * @param Error     L’erreur à tester.
     * @returns         true si c’est une erreur d’autorisation, false sinon.
     */
    public isUnauthorizedException ( Error : any ) : boolean
    {
        let Err = Error ;
        if ( typeof Err == "object" )
        {
            if ( "rejection" in Err )
                Err = Err["rejection"] ;
            if ( "status" in Err )
                return ( Err["status"] === 401 ) ;
        }
        return false ;
    }

    /**
     * Déterminer si une erreur est une absence d’autorisation.
     *
     * @param Error     L’erreur à tester.
     * @returns         true si c’est une erreur d’autorisation, false sinon.
     */
    public isForbiddenException ( Error : any ) : boolean
    {
        let Err = Error ;
        if ( typeof Err == "object" )
        {
            if ( "rejection" in Err )
                Err = Err["rejection"] ;
            if ( "status" in Err )
                return ( Err["status"] === 403 ) ;
        }
        return false ;
    }

    /**
     * Obtenir le message de base d’une erreur.
     * @param Error     L’erreur à examiner.
     * @returns         Le message de base de l’erreur.
     */
    public getErrorMessage ( Error : any ) : string
    {
        let Err = this.getBaseException ( Error ) ;
        if ( "message" in Err )
            return ( Err["message"] ) ;
        else
            return ( "Erreur inconnue." ) ;
    }

    /**
     * Afficher une boîte de dialogue contenant un message d’erreur.
     * @param Title         Le titre en haut de la boîte.
     * @param ShortMessage  Un message clair et concis qui devrait être compréhensible.
     * @param FullMessage   Un message facultatif pour un expert qui donne plus de détails (comme contenu de la pile etc.).
     * @param Dialog    Le service de dialogues Angular pour les cas où l’appel est avant l’initialisation de l’injecteur.
     */
    public async reportErrorMessage(
            Title: string,
            ShortMessage: string,
            FullMessage: string | null,
            Dialog: MatDialog | null = null): Promise<void> {
        let DlgConfig = new MatDialogConfig<any>();
        DlgConfig.data =
            {
                DialogTitle       : Title,
                DialogText        : ShortMessage,
                CanShowFullMessage: (FullMessage !== null),
                FullMessage       : FullMessage
            };

        let Dlg: MatDialog;
        if (Dialog === null) {
            Dlg = this.m_Dialog;
        } else {
            Dlg = Dialog;
        }

        let DlgRef = Dlg.open(ErrorHandlingFrontendAlertDialogComponent, DlgConfig);
        return firstValueFrom(DlgRef.afterClosed());
    }

    /**
     * Signaler une erreur lors d’une opération.
     * @param Title     Le titre à mettre sur le message.
     * @param Error     L’exception soulevé.
     * @param Dialog    Le service de dialogues Angular pour les cas où l’appel est avant l’initialisation de l’injecteur.
     */
    public reportException(Title: string, Error: any, Dialog: MatDialog | null = null): Promise<void> {
        // Essayer de trouver le vrai message
        let Exc = this.getBaseException(Error);

        let Message: string = 'Unknown error.';
        let FullMessage: string | null = null;
        if ('stack' in Error) {
            FullMessage = Error.stack;
        }

        let Msg = Exc['message'];
        if (typeof Msg === 'string') {
            Message = Msg;
        } else {
            if (FullMessage !== null) {
                Message = FullMessage;
                FullMessage = null;
            }
        }

        return this.reportErrorMessage(Title, Message, FullMessage, Dialog);
    }

}

