import { TranslationSet } from '../models/translationset.model' ;
import { SmexFrontendUtils } from './frontend.utils';
import { Inject, Injectable } from '@angular/core';
import { TranslationFrontendRegistry, MODULE_ID } from './translationfrontend.registry';

@Injectable()
export class TranslationFrontendService {

    private _translations: TranslationSet = {};

    constructor(registry: TranslationFrontendRegistry, @Inject(MODULE_ID) private moduleId: string) {
        this._translations = registry.getTranslations(this.moduleId);
    }

    protected get translations(): TranslationSet {
        return this._translations;
    }

    protected set translations(translations: TranslationSet) {
        this._translations = translations;
    }

    /**
     * Obtenir la traduction pour une chaîne depuis un ensemble de traductions.
     *
     * @param translationsOrTranslationKey
     * @param translationKey    L’identifiant qui identifie la chaîne.
     * @return
     */
    public hasTranslation(translationsOrTranslationKey: TranslationSet | string, translationKey?: string): boolean {
        let set : TranslationSet;
        let key: string;

        if (SmexFrontendUtils.isString(translationsOrTranslationKey)) {
            set = this.translations;
            key = translationsOrTranslationKey as string;
        } else {
            set = translationsOrTranslationKey as TranslationSet;
            key = translationKey as string;
        }

        return key in set;
    }

    /**
     * Obtenir la traduction pour une chaîne depuis un ensemble de traductions.
     *
     * @param translationsOrTranslationKey    L’identifiant qui identifie la chaîne.
     * @param translationKey
     * @param values            valeurs facultatives à inclure dans la chaîne.
     */
    public getTranslation(
            translationsOrTranslationKey: TranslationSet | string,
            translationKey?: string | { [key: string]: string },
            values?: { [key: string]: string }): string {
        let set : TranslationSet;
        let key: string;
        let vals: { [key: string]: string } | undefined;

        if (SmexFrontendUtils.isString(translationsOrTranslationKey)) {
            set = this.translations;
            key = translationsOrTranslationKey as string;
            vals = translationKey as { [key: string]: string };
        } else {
            set = translationsOrTranslationKey as TranslationSet;
            key = translationKey as string;
            vals = values;
        }

        if (this.hasTranslation(set, key)) {
            let res: string = set[key];
            if (SmexFrontendUtils.isObject(vals)) {
                Object.entries(vals!)
                      .forEach(([search, replacement]) => {
                          const searchPattern =
                              `\\$${search.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}\\$`;
                          res = res.replace(
                              new RegExp(searchPattern, 'g'),
                              replacement ? replacement.replace(/\$/g,"$$$$") : '');
                      });
            }
            return res;
        }
        return ('NO TRANSLATION [' + key + ']');
    }

}
