import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslatePipe } from './pipes';
import { NotAllowedComponent, PageNotFoundComponent } from './components';
import { ConfigurationFrontendService } from './services';

export { SmexFrontendUtils } from './services/frontend.utils';
export { TranslationFrontendService } from './services/translationfrontend.service';
export { UserFrontendService } from './services/userfrontend.service';
export { TranslationSet } from './models/translationset.model' ;
export { TranslationSets } from './models/translationsets.model' ;

@NgModule({
    declarations: [
        NotAllowedComponent,
        PageNotFoundComponent,
        TranslatePipe
    ],
    imports     : [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule
    ],
    exports     : [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        TranslatePipe,
        NotAllowedComponent,
        PageNotFoundComponent
    ],
    providers   : [
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigurationFrontendService) => {
                return () => configService.initialize()
            },
            deps: [ConfigurationFrontendService],
            multi: true
        }
    ]
})
export class SharedModule {
}
