import { Inject, Injectable } from '@angular/core';

import { MODULE_ID, TranslationFrontendRegistry } from '@numen/smex-lib-common-frontend';
import { CfnEcmFrontConfigurationService } from './cfnecmfront-configuration.service';

@Injectable({
    providedIn: "root"
})
export class CfnEcmFrontInitialisationService {
    public initialised: Promise<boolean>;

    constructor(
            private m_ConfigurationService: CfnEcmFrontConfigurationService,
            private translationsRegistry: TranslationFrontendRegistry,
            @Inject(MODULE_ID) private moduleId: string) {
        this.initialised = this.initialise();
    }

    private async initialise(): Promise<boolean> {
        await this.m_ConfigurationService.initialise();
        await this.translationsRegistry.loadTranslations(this.moduleId);
        return true;
    }
}
