import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { Subscription } from "rxjs";

@Component({
    selector: "app-not-allowed",
    templateUrl: "./not-allowed.component.html",
    styleUrls: ["./not-allowed.component.scss"]
})
export class NotAllowedComponent implements OnInit {
    private routeDateSubscription: Subscription;

    constructor(private route: ActivatedRoute, private router: Router) {
        this.routeDateSubscription = this.route.data.subscribe(value => console.log('new route data: ', value));
    }

    ngOnInit() {
        console.log("route : ", this.route);
    }

    ngOnDestroy(): void {
        this.routeDateSubscription.unsubscribe();
    }
}
