/** angular */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/** end angular */

const routes: Routes = [
    {
        path        : 'cfn',
        loadChildren: () =>
            import('./features/main/main.module').then((module) => module.MainModule)
    },
    { path: '', redirectTo: 'cfn', pathMatch: 'full' },
    { path: 'unauthorized', redirectTo: 'cfn/login' },
    { path: 'not-allowed', redirectTo: 'cfn/not-allowed' },
    { path: 'not-found', redirectTo: 'cfn/not-found' },
    { path: '**', redirectTo: 'cfn' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: true })],
    exports: [RouterModule]
})
export class AppModuleRouting {
}
