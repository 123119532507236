import { Injectable } from "@angular/core" ;

import { ConfigurationFrontendService } from "@numen/smex-lib-common-frontend" ;

@Injectable({
  providedIn: 'root'
})
export class CfnEcmFrontConfigurationService
{

    constructor ( private m_ConfigurationService : ConfigurationFrontendService )
    {
    }

    /** Appelé pour initialiser les données.
     */
    public async initialise () : Promise<void>
    {
    }

    /**
     * Obtenir un chemin complet pour un appel à l’API de la module de recherche production digitale.
     * @param Path    Le chemin à ajouter à l’adresse de base.
     */
    public getCfnEcmApiUri ( Path : string )
    {
        return ( this.m_ConfigurationService.getBackendApiUri ( "cfnecm" ) + Path ) ;
    }

}
