import { Pipe, PipeTransform } from '@angular/core';
import { TranslationFrontendService } from '../services/translationfrontend.service';

@Pipe({
    name: 'translate'
})
export class TranslatePipe implements PipeTransform {
    constructor(private m_TranslationService: TranslationFrontendService) {
    }

    transform(translationKey: string, values?: { [key: string]: string }, returnKeyIfNoTranslation: boolean = true): any {
        if (returnKeyIfNoTranslation !== false && !this.m_TranslationService.hasTranslation(translationKey)) {
            return translationKey;
        }
        return this.m_TranslationService.getTranslation(translationKey, values);
    }
}
