import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { WaitingFrontendModule } from '../waitingfrontend/waitingfrontend.module' ;

import { AuthorizationFrontendRoutingModule } from './authorizationfrontend-routing.module';

import { SharedModule } from '../shared/shared.module';
import { AuthorizationFrontendCallbackComponent } from './components/authorizationfrontend-callback.component';

export { AuthorizationFrontendGuard } from './guards/authorizationfrontend-guard' ;
export { AuthorizationFrontendService } from './services/authorizationfrontend.service' ;

@NgModule({
    declarations: [AuthorizationFrontendCallbackComponent],
    imports     :
        [
            RouterModule,
            HttpClientModule,
            SharedModule,
            WaitingFrontendModule,
            AuthorizationFrontendRoutingModule
        ],
    providers   : []
})
export class AuthorizationFrontendModule {
}
