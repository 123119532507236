//* Angular
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';
import {
    ConfigurationFrontendService,
    SmexFrontendUtils,
    TranslationFrontendRegistry,
    UserFrontendService
} from '../../shared/services';

@Injectable({
    providedIn: "root"
})
export class AuthorizationFrontendService
{
    // Le nom à utiliser pour stocker un token en local
    private static LOCAL_AUTHID_STORAGE_NAME = "IdentityAccessToken" ;
    private static LOCAL_USERAUTHORIZATIONS_STORAGE_NAME = "Authorizations" ;

    /**
     * Constructeur pour le service d’autentification.
     * @param m_HttpClient            Gestion HTTP.
     * @param m_ConfigurationService   Service de configuration.
     * @param m_UserFrontendService
     * @param m_TranslationRegistry
     */
    constructor(
        private m_HttpClient: HttpClient,
        private m_ConfigurationService: ConfigurationFrontendService,
        private m_UserFrontendService: UserFrontendService,
        private m_TranslationRegistry: TranslationFrontendRegistry) {
    }

    /**
     * Convertir une chaîne en codage base 32.
     * @param InStr   La chaîne à convertir.
     */
    private encodeBase32 ( InStr : string ): string
    {
        let Alphabet: string= 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567' ;

        const length = InStr.length ;
        const view = Uint8Array.from(InStr.split("").map(x => x.charCodeAt(0)))

        let bits = 0;
        let value = 0;
        let OutStr = '';

        for (let i = 0; i < length; i++)
        {
            value = (value << 8) | view[i]!;
            bits += 8;

            while (bits >= 5)
            {
              OutStr += Alphabet[(value >>> (bits - 5)) & 31];
              bits -= 5;
            }
        }

        if (bits > 0)
          OutStr += Alphabet[(value << (5 - bits)) & 31];

        return ( OutStr ) ;
    }

    /**
     * Décoder une chaîne base32 en chaîne.
     * @param InStr
     */
    private decodeBase32 ( InStr : string ) : string
    {
        let alphabet: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567' ;
        let cleanedInput: string = InStr.toUpperCase().replace(/=+$/, '');

        const { length } = cleanedInput;

        let bits = 0;
        let value = 0;

        let index = 0;
        let OutStr : string = "" ;

        for (let i = 0; i < length; i++)
        {
            value = (value << 5) | alphabet.indexOf(cleanedInput[i]!) ;
            bits += 5;

            if (bits >= 8)
            {
                OutStr += String.fromCharCode ( (value >>> (bits - 8)) & 255 ) ;
                bits -= 8;
            }
        }

        return ( OutStr ) ;
    }

    /**
     * Obtenir un chemin complet pour un appel à l’API de la module d’autorisation.
     * @param Path    Le chemin à ajouter à l’adresse de base.
     */
    public getAuthorizationApiUri(Path: string): string {
        return this.m_ConfigurationService.getBackendApiUri('authorizations') + Path;
    }

    public getAuthorizationCallbackUri(): string {
        return this.m_ConfigurationService.getFrontEndUri('authorization') + '/callback';
    }

    /**
     * Obtenir l’id d’autorisation stocké dans le navigateur.
     */
    public getAuthorizationId(): string | null {
        return (window.localStorage.getItem(AuthorizationFrontendService.LOCAL_AUTHID_STORAGE_NAME));
    }

    /**
     * Obtenir l’id d’autorisation stocké dans le navigateur.
     */
    private getUserAuthorizations () : string[] | null
    {
        const auths = window.localStorage.getItem ( AuthorizationFrontendService.LOCAL_USERAUTHORIZATIONS_STORAGE_NAME );
        if (auths) {
            try {
                return JSON.parse(auths);
            } catch (_) {
                return null;
            }
        }
        return null;
    }

    /**
     * Déterminer si on a un id d’autorisation actuellement.
     */
    private hasAuthorizationId () : boolean
    {
        return ( this.getAuthorizationId() !== null ) ;
    }

    /**
     * Supprimer l’identifiant d’autorisation s’il y en a.
     */
    private deleteAuthorization () : void
    {
        window.localStorage.removeItem ( AuthorizationFrontendService.LOCAL_AUTHID_STORAGE_NAME ) ;
        window.localStorage.removeItem ( AuthorizationFrontendService.LOCAL_USERAUTHORIZATIONS_STORAGE_NAME ) ;
        this.m_UserFrontendService.clear();
    }

    /**
     * Vérifier qu’on a tous les détails de l’utilisateur.
     * @returns   true si tous est bon, false s’il y avait un problème.
     */
    private async verifyUserInfo () : Promise<boolean>
    {
        let result : boolean = true ;

        if ( (this.m_UserFrontendService.sub === null) || (this.getUserAuthorizations() === null) )
        {
            result = false ;

            const url: string = this.getAuthorizationApiUri(`/sessions/${this.getAuthorizationId()}/data`);
            const res: any = await firstValueFrom(this.m_HttpClient.get(url));
            if (SmexFrontendUtils.isString(res.sub)) {
                this.m_UserFrontendService.userData = res;
                await this.m_TranslationRegistry.reloadTranslations();
                result = true;
            }
            if (Array.isArray(res.authorizations)) {
                window.localStorage.setItem(
                    AuthorizationFrontendService.LOCAL_USERAUTHORIZATIONS_STORAGE_NAME,
                    JSON.stringify(res.authorizations));
            }
        }

        return ( result ) ;
    }

    /**
     * Déterminer si on a une authentification valable.
     */
    public async hasValidUserAuthentication () : Promise<boolean>
    {
        if ( ! this.hasAuthorizationId() )
            return false;

        const url : string = this.getAuthorizationApiUri ( `/sessions/${this.getAuthorizationId()}/valid` ) ;
        let res: any = await firstValueFrom(this.m_HttpClient.get(url));

        let HasValidAuth : boolean = false ;
        if ( "authorization_id_valid" in res )
            HasValidAuth = res["authorization_id_valid"] ;

        // Il est possible qu’on ait gardé l’identifiant depuis une session navigateur précédente. Si l’autorisation
        // est valable, il faut aussi vérifier qu’on a tous les détails.
        if ( HasValidAuth )
            HasValidAuth = await this.verifyUserInfo () ;

        // Si ce n’est pas valable on vérifie que tout est supprimé
        if ( ! HasValidAuth )
            this.deleteAuthorization () ;

        return ( HasValidAuth ) ;
    }

    /**
     * Rediriger le navigateur vers l’URL pour obtenir un code d’autorisation en demandant le
     * nom d’utilisateur et mot de passe à l’utilisateur.
     *
     * @param stateURL    L’URL où il faut aller après autorisation.
     */
    public async redirectToLogin(stateURL: string): Promise<void> {
        const callbackUri = this.getAuthorizationCallbackUri();

        // D’abord, on demande au backend l’URL d’autorisation. Cet appel n’a pas besoin d’être autorisé.
        const backURL: string = this.getAuthorizationApiUri('/authorizeUri') +
                                '?redirect_uri=' + callbackUri +
                                '&state=' + this.encodeBase32(stateURL);
        const res: any = await firstValueFrom(this.m_HttpClient.get(backURL));

        // Rediriger le navigateur.
        document.location.href = res['authorize_uri'];
    }

    /**
     * Vérifier si l’utilisateur peut activer l’URL indiqué.
     *
     * @param URL     L’URL à tester.
     * @param authNeeded
     */
    public async checkCanActivate(URL: string, authNeeded?: string): Promise<boolean> {
        if (authNeeded) {
            if (authNeeded === 'everyone') {
                return true;
            }
            const userAuths = this.getUserAuthorizations();
            if (userAuths === null) {
                return false;
            }
            return userAuths.includes(authNeeded);
        }
        return true;
    }

    /**
     * Créer une session avec un token d’accès.
     * @param code      Un code retourné par le service d’autorisation qui permet de récupérer un token.
     */
    public async createSession(code: string) {
        const url: string = this.getAuthorizationApiUri('/sessions');
        const res: any = await firstValueFrom(
            this.m_HttpClient.post(url, {code, redirectUri: this.getAuthorizationCallbackUri()}));
        window.localStorage.setItem(AuthorizationFrontendService.LOCAL_AUTHID_STORAGE_NAME, res['session_id']);
    }

    public redirectTo(state: string) {
        const stateURL = this.decodeBase32(state);
        document.location.href = SmexFrontendUtils.createUrl(this.m_ConfigurationService.baseHref, stateURL);
    }

    /**
     * Appelé pour fermer la session d’un utilisateur.
     */
    public async closeSession() {
        this.deleteAuthorization();
        const url: string = this.getAuthorizationApiUri(`/sessions/${this.getAuthorizationId()}`);
        await firstValueFrom(this.m_HttpClient.delete(url));
    }

    /**
     * Indiquer si l’utilisateur a l’autorisation indiqué.
     * @param Auth    Autorisation à tester.
     */
    public hasThisAuthorization ( Auth : string ) : boolean
    {
        let UserAuths = this.getUserAuthorizations () ;
        if ( UserAuths === null )
            return false;
        else
            return ( UserAuths.includes ( Auth ) ) ;
    }

    /**
     * Indiquer si l’utilisateur a une des autorisations indiqués.
     * @param Auths   Autorisations à tester.
     */
    public hasOneOfTheseAuthorizations ( Auths : string[] ) : boolean
    {
        let UserAuths = this.getUserAuthorizations () ;
        if ( UserAuths === null )
            return false;
        else
        {
            for ( let Auth of Auths )
            {
                if ( UserAuths.includes(Auth) )
                    return true;
            }
            return false;
        }
    }

    /**
     * Lancer une requête get avec autorisation incluse.
     * @param url           L’URL destination de la reqête.
     * @param responseType  Le type de valeur qui sera retourné. Sera JSON par défaut.
     */
    public get<T>(url: string, responseType: string | null = null): Promise<T> {
        let Headers = new HttpHeaders().set ( "Authorization", "Bearer " + this.getAuthorizationId() ) ;
        let Opt : any = { headers : Headers } ;
        if (responseType !== null) {
            Opt['responseType'] = responseType;
        }
        return firstValueFrom(this.m_HttpClient.get<T>(url, Opt)) as Promise<T>;
    }

    /**
     * Lancer une requête post avec autorisation incluse.
     * @param url     L’URL destination de la reqête.
     * @param body    Le corps du message.
     * @param responseType  Le type de valeur qui sera retourné. Sera JSON par défaut.
     */
    public post<T>(url: string, body: any, responseType: string | null = null): Promise<T> {
        let Headers = new HttpHeaders().set ( "Authorization", "Bearer " + this.getAuthorizationId() ) ;
        let Opt : any = { headers : Headers } ;
        if (responseType !== null) {
            Opt['responseType'] = responseType;
        }
        return firstValueFrom(this.m_HttpClient.post<T>(url, body, Opt)) as Promise<T>;
    }

    /**
     * Lancer une requête put avec autorisation incluse.
     *
     * @param url     L’URL destination de la reqête.
     * @param body    Le corps du message.
     * @param responseType  Le type de valeur qui sera retourné. Sera JSON par défaut.
     */
    public put<T>(url: string, body: any, responseType: string | null = null): Promise<T> {
        let Headers = new HttpHeaders().set ( "Authorization", "Bearer " + this.getAuthorizationId() ) ;
        let Opt : any = { headers : Headers } ;
        if (responseType !== null) {
            Opt['responseType'] = responseType;
        }
        return firstValueFrom(this.m_HttpClient.put<T>(url, body, Opt)) as Promise<T>;
    }

    /**
     * Lancer une requête patch avec autorisation incluse.
     * @param url     L’URL destination de la reqête.
     * @param body    Le corps du message.
     * @param responseType  Le type de valeur qui sera retourné. Sera JSON par défaut.
     */
    public patch<T>(url: string, body: any, responseType: string | null = null): Promise<T> {
        let Headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getAuthorizationId());
        let Opt : any = { headers : Headers } ;
        if (responseType !== null) {
            Opt['responseType'] = responseType;
        }
        return firstValueFrom(this.m_HttpClient.patch<T>(url, body, Opt)) as Promise<T>;
    }

    /**
     * Lancer une requête delete avec autorisation incluse.
     * @param url     L’URL destination de la reqête.
     */
    public delete<T>(url: string): Promise<T> {
        let Headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getAuthorizationId());
        return firstValueFrom(this.m_HttpClient.delete<T>(url, { headers: Headers }));
    }
}

