import { NgModule } from '@angular/core';

import { CommonModule } from "@angular/common" ;
import { MaterialModule } from "../shared/material.module" ;

import { ErrorHandlingFrontendAlertDialogComponent } from "./components/errorhandlingfrontend-alert-dialog/errorhandlingfrontend-alert-dialog.component" ;

import { AuthorizationFrontendModule } from "../authorizationfrontend/authorizationfrontend.module" ;

import { ErrorHandlingFrontendService } from "./services/errorhandlingfrontend.service" ;
export { ErrorHandlingFrontendService } from "./services/errorhandlingfrontend.service" ;

import { SharedModule } from '../shared/shared.module';
import { MODULE_ID, TranslationFrontendRegistry, TranslationFrontendService } from '../shared/services';
import { GlobalErrorHandler } from './handlers/globalerrorhandler';

export { GlobalErrorHandler } from "./handlers/globalerrorhandler" ;


@NgModule({
  imports :
    [
        CommonModule,
        MaterialModule,
        AuthorizationFrontendModule,
        SharedModule
    ],
    declarations:
    [
        ErrorHandlingFrontendAlertDialogComponent
    ],
  providers :
    [
        ErrorHandlingFrontendService,
        GlobalErrorHandler,
        {provide: MODULE_ID, useValue: "common"},
        TranslationFrontendRegistry,
        TranslationFrontendService,
    ]
})
export class ErrorHandlingFrontendModule {}
