<div class="boo-ghost">
    <div class="boo-wrapper">
        <div class="boo">
            <div class="face"></div>
        </div>
        <div class="shadow"></div>
    </div>
</div>
<h1>404</h1>
<pre>{{ "PageNotFound" | translate }}</pre>
