export enum SmartGedPermissions {
    ALL = 'cmis:all',
    READ = 'cmis:read',
    WRITE = 'cmis:write',

    DELETE = 'nvlt:delete',
    EXECUTE = 'nvlt:execute',
    READ_PROPERTIES = 'nvlt:readProperties',
    READ_CHILDREN = 'nvlt:readChildren',
    READ_CONTENT = 'nvlt:readContent',
    READ_ACL = 'nvlt:readACL',
    WRITE_PROPERTIES = 'nvlt:writeProperties',
    WRITE_CHILDREN = 'nvlt:writeChildren',
    WRITE_ACL = 'nvlt:writeACL'
}

export enum CfnEcmAuthorizations {
    All = 'cfn-ecm:all',
    ADMIN_G = 'smvlt:grp-uti-g',
    ADMIN_F = 'smvlt:grp-uti-f',
    UTI_S = 'smvlt:grp-uti-s',


    CREATE_SPACE = 'smvlt:svc:createSecuredSpace',
    LIST_SPACE = 'smvlt:svc:listSecuredSpaces',
    INFO_SPACE = 'smvlt:svc:getSecuredSpaceInfo',
    APPLY_SPACE_ACL = 'smvlt:svc:applySecuredSpaceACL',
    GET_SPACE_ROOT_FOLDER = 'smvlt:svc:getSecuredSpaceRootFolder',
    DELETE_SPACE = 'smvlt:svc:deleteSecuredSpace',
    CREATE_DOCUMENT = 'smvlt:svc:createSecuredDocument',
    CREATE_APPLICATION_LOG_ARCHIVE = 'smvlt:svc:createApplicationLogArchive',
    GET_DOCUMENT_PROPERTIES = 'smvlt:svc:getSecuredDocumentProperties',
    GET_DOCUMENT = 'smvlt:svc:getSecuredDocument',
    GET_DOCUMENT_BINARY = 'smvlt:svc:getSecuredDocumentBinary',
    GET_DOCUMENT_FULL = 'smvlt:svc:getSecuredDocumentFull',
    CHECK_DOCUMENT = 'smvlt:svc:checkSecuredDocumentIntegrity',
    CHECK_LOGS = 'smvlt:svc:checkApplicationLogEntryIntegrity',
    AUTO_CHECK_DOCUMENT = 'smvlt:svc:autoCheckSecuredDocumentIntegrity',
    AUTO_CHECK_LOGS = 'smvlt:svc:autoCheckApplicationLogEntryIntegrity',
    DELETE_DOCUMENT = 'smvlt:svc:deleteSecuredDocument',
    UPDATE_DOCUMENT_PROPERTIES = 'smvlt:svc:updateSecuredDocumentProperties',
    ADD_HOLD_TO_DOCUMENT = 'smvlt:svc:addHoldToSecuredDocument',
    REMOVE_HOLD_TO_DOCUMENT = 'smvlt:svc:removeHoldToSecuredDocument',
    GET_LOGS = 'smvlt:svc:getApplicationLog',
    GET_DOCUMENTS_LOGS = 'smvlt:svc:getDocumentsApplicationLog',
    LIST_DOCUMENTS = 'smvlt:svc:listSecuredDocuments',
    COUNT_DOCUMENTS = 'smvlt:svc:countSecuredDocuments',
    GET_DOCUMENT_ANCESTORS = 'smvlt:svc:getSecuredDocumentAncestors',
    CREATE_FOLDER = 'smvlt:svc:createFolder',
    GET_FOLDER_PROPERTIES = 'smvlt:svc:getFolderProperties',
    UPDATE_FOLDER_PROPERTIES = 'smvlt:svc:updateFolderProperties',
    GET_FOLDER_ANCESTORS = 'smvlt:svc:getFolderAncestors',
    GET_FOLDER_CHILDREN = 'smvlt:svc:getFolderChildren',
    DELETE_FOLDER = 'smvlt:svc:deleteFolder',

    CREATE_USER = 'smvlt:svc:createUti',
    GET_USER_PROPERTIES = 'smvlt:svc:getUti',
    UPDATE_USER_PROPERTIES = 'smvlt:svc:updateUti',
    UPDATE_USER_ACTIVATION = 'smvlt:svc:updateUtiActivation',
    UPDATE_USER_PASSWORD = 'smvlt:svc:updateUtiPassword',
    UNLOCK_USER = 'smvlt:svc:unlockUti',
    DELETE_USER = 'smvlt:svc:deleteUti',
    GET_USER_ACL = 'smvlt:svc:getUtiACL',
    APPLY_USER_ACL = 'smvlt:svc:applyUtiACL',
    LIST_USERS = 'smvlt:svc:listUtis',
    CREATE_GROUP = 'smvlt:svc:createGrp',
    GET_GROUP_PROPERTIES = 'smvlt:svc:getGrp',
    UPDATE_GROUP_PROPERTIES = 'smvlt:svc:updateGrp',
    DELETE_GROUP = 'smvlt:svc:deleteGrp',
    GET_GROUP_ACL = 'smvlt:svc:getGrpACL',
    APPLY_GROUP_ACL = 'smvlt:svc:applyGrpACL',
    LIST_GROUPS = 'smvlt:svc:listGrps',

    INTERNAL_SEARCH = 'smvlt:svc:searchInternal',
}
