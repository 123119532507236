import { Injectable } from '@angular/core' ;
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { AuthorizationFrontendService } from '../services/authorizationfrontend.service' ;

@Injectable({
    providedIn: 'root'
})
export class AuthorizationFrontendGuard implements CanActivate {

    constructor(private router: Router, private m_AuthorizationService: AuthorizationFrontendService) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (!await this.m_AuthorizationService.hasValidUserAuthentication()) {
            await this.m_AuthorizationService.redirectToLogin(state.url);
            return false;
        }
        const authorization = route.data['authorization'] as string;
        const allowed = await this.m_AuthorizationService.checkCanActivate(state.url, authorization);
        if (!allowed) {
            return this.router.createUrlTree(['/not-allowed']);
        }
        return true;
    }
}
