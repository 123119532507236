import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthorizationFrontendCallbackComponent } from "./components/authorizationfrontend-callback.component"

const routes: Routes = [
    {
      path: "",
      children:
        [
            {
                path: "callback",
                component: AuthorizationFrontendCallbackComponent
            },
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthorizationFrontendRoutingModule
{
}

