
import { Component, Inject } from '@angular/core' ;
import { MAT_DIALOG_DATA } from "@angular/material/dialog" ;

@Component({
    selector: 'errorhandlingfrontend-alert-dialog',
    templateUrl: './errorhandlingfrontend-alert-dialog.component.html',
    styleUrls: ['./errorhandlingfrontend-alert-dialog.component.scss'],
})
export class ErrorHandlingFrontendAlertDialogComponent
{

    // Le titre à mettre sur la boîte de dialogue.
    public DialogTitle = "" ;

    // La classe pour le titre (par défaut c’est un message d’erreur)
    public TitleClass = "ErrorTitle" ;

    // La classe pour l’icône
    public IconClass = "ErrorIcon" ;

    // L’icône à afficher
    public Icon = "error" ;

    // Le texte pour la boîte de dialogue.
    public DialogText = "" ;

    // Si un message d’erreur complet peut être affiché en option, le message complet.
    public FullMessage = "" ;

    // Si un message d’erreur complet peut être affiché en option.
    public CanShowFullMessage = false ;

    // Si un bouton annuler doit être disponible.
    public CanCancel = false ;

    // Quand on peut annuler, c’est le nom à mettre sur le bouton d’action.
    public ActionButtonName : string | null = null ;

    // Si on a affiché le message complet
    public FullMessageShown = false ;

    public constructor (@Inject(MAT_DIALOG_DATA) data : any) {
        if ( "DialogTitle" in data )
            this.DialogTitle = data["DialogTitle"] ;
        if ( "Level" in data )
        {
            if ( data["Level"] === "Info" )
            {
                this.TitleClass = "InfoTitle" ;
                this.IconClass = "InfoIcon" ;
                this.Icon = "info" ;
            }
        }
        if ( ("DialogText" in data) && (typeof data["DialogText"] === "string") )
            this.DialogText = data["DialogText"].replace(/(?:\r\n|\r|\n)/g,"<br/>") ;
        if ( ("FullMessage" in data) && (typeof data["FullMessage"] === "string") )
            this.FullMessage = data["FullMessage"].replace(/(?:\r\n|\r|\n)/g,"<br/>") ;
        if ( "CanShowFullMessage" in data )
            this.CanShowFullMessage = data["CanShowFullMessage"] ;
        if ( "CanCancel" in data )
            this.CanCancel = data["CanCancel"] ;
        if ( "ActionButtonName" in data )
            this.ActionButtonName = data["ActionButtonName"] ;
        this.FullMessageShown = false ;
    }

    /**
     * Appelé quand l’utilisateur clique sur le bouton pour afficher ou cacher le message complet.
     */
    public onShowHideFullMessage(): void {
        if (this.CanShowFullMessage) {
            this.FullMessageShown = !this.FullMessageShown;
        }
    }
}

