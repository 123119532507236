export class SmexFrontendUtils {
    public static isString(value: any): boolean {
        return Object.prototype.toString.call(value) === '[object String]';
    }

    public static isObject(value: any): boolean {
        return value != null && typeof value === 'object';
    }

    public static createUrl(...fragments: string[]): string {
        let result = '';
        if (fragments && fragments.length) {
            for (let i = 0; i < fragments.length; i++) {
                if (!fragments[i]) {
                    continue;
                }
                if (result.length && result.endsWith("/")) {
                    result = result.substring(0, result.length - 1);
                }
                if (result.length) {
                    result += "/";
                }
                if (result.length && fragments[i].startsWith("/")) {
                    result += fragments[i].substring(1);
                } else {
                    result += fragments[i];
                }
            }
        }
        return result;
    }

}
