import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core" ;
import { Router } from "@angular/router" ;
import { MatDialog } from "@angular/material/dialog";

import { AuthorizationFrontendService } from "../../authorizationfrontend/authorizationfrontend.module" ;
import { ErrorHandlingFrontendService } from "../services/errorhandlingfrontend.service" ;
import { MODULE_ID, TranslationFrontendRegistry, TranslationFrontendService } from '../../shared/services';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler
{
    constructor ( private m_Injector : Injector , private ngZone: NgZone)
    {
    }

    /**
     * Gérer une erreur qui n’a pas été traitée par une application.
     * @param Error     L’erreur à traiter.
     */
    async handleError(Error: any): Promise<void> {
        console.error(Error);
        let ErrorHandlingService = this.m_Injector.get(ErrorHandlingFrontendService);

        if (ErrorHandlingService.isUnauthorizedException(Error)) {
            // Se diriger vers l’écran de login
            let authService = this.m_Injector.get(AuthorizationFrontendService);
            let router: Router = this.m_Injector.get(Router);
            return authService.redirectToLogin(router.url);
        } else if (!Error.message?.startsWith("NG0100: ExpressionChangedAfterItHasBeenCheckedError")) {
            // Montrer l’erreur
            let translationRegistry = this.m_Injector.get(TranslationFrontendRegistry);
            await translationRegistry.loadTranslations(this.m_Injector.get(MODULE_ID));
            let TranslationService = this.m_Injector.get(TranslationFrontendService);
            let Dialog = this.m_Injector.get(MatDialog);

            if (ErrorHandlingService.isForbiddenException(Error)) {

                this.ngZone.run(async () => {
                    return ErrorHandlingService.reportErrorMessage(
                        TranslationService.getTranslation('ErrorDialogTitle'),
                        TranslationService.getTranslation('NotAllowed'),
                        null,
                        Dialog);
                });
            } else {
                this.ngZone.run(async () => {
                    return ErrorHandlingService.reportException(
                        TranslationService.getTranslation('ErrorDialogTitle'),
                        Error,
                        Dialog);
                });
            }
        }
    }
}
