import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CfnEcmUserService } from '@smex-app-cfn/smex-module-cfn-ecm-frontend';
import { finalize, Observable, tap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class InProgressInterceptor implements HttpInterceptor {

    constructor(private router: Router, private userService: CfnEcmUserService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.userService.actionInProgress = true;
        return next.handle(req).pipe(
            tap({
                error: (error) => {
                    if (error.status == 401) {
                        this.router.navigate(["/unauthorized"]);
                    }
                }
            }),
            finalize(() => this.userService.actionInProgress = false));
        // return next.handle(req).pipe(finalize(() => setTimeout(() => this.userService.actionInProgress = false, 2000)));
    }

}
