import { Injectable } from '@angular/core';
import { UserData } from '../models';

@Injectable({
    providedIn: 'root'
})
export class UserFrontendService {
    private static LOCAL_USER_STORAGE_NAME = 'UserData';

    private _localeChanged = false;
    private _defaultLocale = "fr-FR";

    private _userData: UserData|undefined|null = undefined;

    set userData(userData: UserData|null) {
        this._localeChanged = true;
        this._userData = userData;
        window.localStorage.setItem(UserFrontendService.LOCAL_USER_STORAGE_NAME, JSON.stringify(userData));
    }

    get userData(): UserData|null {
        this.loadUserData();
        return this._userData!;
    }

    get sub(): string | null {
        return this.loadUserData()?.sub ?? null;
    }

    get defaultLocale(): string {
        return this._defaultLocale;
    }

    set defaultLocale(locale: string) {
        this._defaultLocale = locale;
    }

    get locale(): string {
        return this.loadUserData()?.locale
               ?? navigator.language
               ?? this.defaultLocale;
    }

    get localeChanged(): boolean {
        return this._localeChanged;
    }

    /**
     * Indiquer si le sub fourni est celui de l’utilisateur actuel.
     * @param Sub     Le sub à tester.
     * @returns       true si c’est l’utilisateur actuel, false sinon.
     */
    public isCurrentUserSub(Sub: string | null) {
        if (Sub === null) {
            return false;
        }
        return this.sub === Sub;
    }

    public clear() {
        window.localStorage.removeItem(UserFrontendService.LOCAL_USER_STORAGE_NAME);
    }

    private loadUserData() {
        if (this._userData === undefined) {
            this._userData = null;
            const str = window.localStorage.getItem(UserFrontendService.LOCAL_USER_STORAGE_NAME);
            if (str) {
                this._userData = JSON.parse(str);
            }
        }
        return this._userData;
    }

}
