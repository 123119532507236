
import { Inject, Injectable, InjectionToken, Injector, Optional } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { SmexFrontendUtils } from './frontend.utils';
import { ActivatedRoute } from '@angular/router';

export const APP_PATH = new InjectionToken<string>('APP_PATH');

@Injectable({
    providedIn: "root"
})
export class ConfigurationFrontendService
{
    private backendUrl: string = "";

    constructor(
            @Optional() @Inject(APP_BASE_HREF) private _baseHref: string,
            @Optional() @Inject(APP_PATH) private _appPath: string,
            private http: HttpClient,
            private injector: Injector) {
        if (!this._baseHref) {
            this._baseHref = '';
        }
    }

    get baseHref():string {
        return this._baseHref;
    }

    public initialize(): Observable<void> {
        let url = this.baseHref;
        if (!url.endsWith("/")) {
            url += "/";
        }
        url += "assets/config.json";
        return this.http.get<any>(url).pipe(
                       tap((conf: any) => this.backendUrl = conf['backend-url']),
                       catchError(err => {
                           console.error("error getting configuration file");
                           console.error(err);
                           return of();
                       }));
    }

    /**
     * Obtenir la partie de l’URL qui indique le nom de l’application.
     */
    public getApplicationPath(): string {
        if (this._appPath) {
            return this._appPath;
        }
        const route = this.injector.get(ActivatedRoute);
        let url = window.location.pathname ;
        if (this.baseHref) {
            if (this.baseHref.startsWith(".")) {
                url = url.substring(this.baseHref.length - 1);
            } else {
                url = url.substring(this.baseHref.length);
            }
        }

        let idx = url.indexOf("/");
        if (idx === 0) {
            idx = url.indexOf("/", 1);
        }
        if (idx > 0) {
            url = url.substring(0, idx);
        }

        return url;
    }

    public getFrontEndUri(moduleName: string): string {
        let baseHref = ""
        if (this.baseHref && this.baseHref !== "/" && this.baseHref != "./") {
            // base href starts with "." we remove it
            baseHref = this.baseHref;
            if (this.baseHref.startsWith(".")) {
                baseHref = baseHref.substring(1);
            }
        }
        return SmexFrontendUtils.createUrl(window.location.origin, baseHref, this.getApplicationPath(), moduleName);
    }

    /**
     * Obtenir un chemin complet pour un appel au backend API d’un module.
     * @param ModuleName    Le nom de module.
     */
    public getBackendApiUri ( ModuleName : string ) : string
    {
        if (this.backendUrl) {
            return SmexFrontendUtils.createUrl(this.backendUrl, ModuleName);
        }
        // Un peu simpliste, mais en principe si c’est localhost c’est du développement et on
        // a deux serveurs, si ce n’est pas, le backend vient de la même adresse que le frontend.
        let URL = window.location.origin ;
        if ( URL.indexOf("localhost") === -1 )
        {
            // Production
            return ( URL + "/api/" + ModuleName ) ;
        }
        else
        {
            // Développement
            return ( "http://localhost:3333/api/" + ModuleName ) ;
        }
    }
}

