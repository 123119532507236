import { Component, OnInit } from '@angular/core' ;
import { Router, UrlTree } from '@angular/router' ;

import { AuthorizationFrontendService } from '../services/authorizationfrontend.service' ;
import { WaitingFrontendService } from '../../waitingfrontend/waitingfrontend.module' ;

@Component({
    selector   : 'authorizationfrontend-callback',
    templateUrl: './authorizationfrontend-callback.component.html'
})
export class AuthorizationFrontendCallbackComponent implements OnInit {
    constructor(
        private m_Router: Router,
        private m_AuthorizationService: AuthorizationFrontendService,
        private m_WaitingService: WaitingFrontendService) {
    }

    async ngOnInit() {
        // Le code peut être dans le fragment ou dans les paramètres query. Il faut le parser nous-mêmes.

        let code: string | null = null;
        let state: string | null = null;

        let urlTree: UrlTree = this.m_Router.parseUrl(this.m_Router.url);

        if (urlTree.queryParamMap.has('code') && urlTree.queryParamMap.has('state')) {
            // fourni comme paramètres
            code = urlTree.queryParamMap.get('code');
            state = urlTree.queryParamMap.get('state');
        } else {
            // Peut-être fourni dans le fragment
            let urlFragment: string | null = urlTree.fragment;
            if (urlFragment !== null) {
                let params = urlFragment.split('&');
                let values: Map<string, string> = new Map<string, string>();
                for (let param of params) {
                    let idx = param.indexOf('=');
                    if (idx > 0) {
                        values.set(param.substring(0, idx), param.substring(idx + 1));
                    }
                }

                if (values.has('code') && values.has('state')) {
                    code = values.get('code') as string;
                    state = values.get('state') as string;
                }
            }
        }

        if ((code != null) && (state != null)) {
            this.m_WaitingService.startWaiting();
            try {
                await this.m_AuthorizationService.createSession(code);
                this.m_AuthorizationService.redirectTo(state);
            } finally {
                this.m_WaitingService.stopWaiting();
            }
        }
    }
}
