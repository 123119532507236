
<h2 matDialogTitle class="{{ TitleClass }}">{{DialogTitle}}</h2>

<mat-dialog-content>
  <section class="DialogContent">
    <section><mat-icon class="Icon {{ IconClass }}">{{ Icon }}</mat-icon></section>
    <div>
      <p class="DialogText" [innerHTML]="DialogText"></p>
      <p *ngIf="FullMessageShown" class="DialogText" [innerHTML]="FullMessage"></p>
    </div>
  </section>
</mat-dialog-content>

<mat-dialog-actions align="end">

  <button id="AlertShowFullMessage" mat-raised-button *ngIf="CanShowFullMessage" (click)="onShowHideFullMessage()">
    {{ (FullMessageShown ? "Dialog_HideFullMessage" : "Dialog_ShowFullMessage") | translate }}
  </button>

  <button id="AlertCancel" mat-raised-button *ngIf="CanCancel" matDialogClose="false">{{ "Cancel" | translate }}</button>
  <button id="AlertAction" mat-raised-button *ngIf="CanCancel" matDialogClose="true" color="warn">{{ ActionButtonName }}</button>

  <button id="AlertClose" mat-raised-button *ngIf="! CanCancel" matDialogClose>{{ "Close" | translate}}</button>

</mat-dialog-actions>

