import { Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigurationFrontendService } from './configurationfrontend.service' ;
import { TranslationSet } from '../models' ;
import { firstValueFrom } from 'rxjs';
import { UserFrontendService } from './userfrontend.service';

export const MODULE_ID = new InjectionToken<string>('MODULE_ID');

enum TranslationState {
    PROVISIONED,
    LOADING,
    LOADED
}

@Injectable({
    providedIn: 'root'
})
export class TranslationFrontendRegistry {

    private registry: Map<string, {translations: TranslationSet, state: TranslationState}> =
        new Map<string, {translations: TranslationSet, state: TranslationState}>();

    constructor(
            private m_ConfigurationService: ConfigurationFrontendService,
            private m_UserFrontendService: UserFrontendService,
            private m_HttpClient: HttpClient) {
    }

    /**
     * Load translations for a module and a language
     *
     * @param moduleId
     *              The module id
     */
    public async loadTranslations(moduleId: string): Promise<void> {
        if (this.registry.has(moduleId)
                && this.registry.get(moduleId)!.state !== TranslationState.PROVISIONED
                && !this.m_UserFrontendService.localeChanged) {
            return;
        }
        if (!this.registry.has(moduleId)) {
            this.registry.set(moduleId, { translations: {}, state: TranslationState.LOADING });
        } else {
            this.registry.get(moduleId)!.state = TranslationState.LOADING;
        }
        const url = this.m_ConfigurationService.getBackendApiUri('translation') + '/' + moduleId;
        let trans: TranslationSet|null = null;
        let lang = this.m_UserFrontendService.locale;
        try {
            trans = await firstValueFrom(this.m_HttpClient.get<TranslationSet>(url + '/' + lang));
        } catch (err: any) {
            console.warn(`Error loading translations ${url}: ${err.message}`);
            try {
                lang = this.m_UserFrontendService.defaultLocale;
                trans = await firstValueFrom(this.m_HttpClient.get<TranslationSet>(url + '/' + lang));
            } catch (err: any) {
                console.error(`Error loading default translations ${url}: ${err.message}`);
            }
        }
        if (trans) {
            Object.assign(this.registry.get(moduleId)!.translations, trans);
            this.registry.get(moduleId)!.state = TranslationState.LOADED;
        }
    }

    /**
     *
     */
    public async reloadTranslations(): Promise<void> {
        for (let mId of Array.from(this.registry.keys())) {
            await this.loadTranslations(mId);
        }
    }

    public getTranslations(moduleId: string): TranslationSet {
        if (!this.registry.has(moduleId)) {
            this.registry.set(moduleId, { translations: {}, state: TranslationState.PROVISIONED });
        }
        return this.registry.get(moduleId)!.translations;
    }

}
