import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { WaitingFrontendService } from "./services/waitingfrontend.service" ;
export { WaitingFrontendService } from "./services/waitingfrontend.service" ;

import { WaitingFrontendComponent } from "./components/waitingfrontend.component" ;
export { WaitingFrontendComponent } from "./components/waitingfrontend.component" ;

@NgModule({
  imports :
    [
        MatProgressSpinnerModule
    ],
  declarations :
    [
        WaitingFrontendComponent,
    ],
  providers :
    [
        WaitingFrontendService
    ],
  exports :
    [
        WaitingFrontendComponent
    ]
})
export class WaitingFrontendModule {}
